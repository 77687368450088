define("discourse/plugins/discourse-anonymous-moderators/discourse/components/anonymous-moderator-tab", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajax, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="anonymous-moderator-switcher">
    <div class="anonymous-moderator-switcher__header">
      <span class="anonymous-moderator-switcher__user">
        {{i18n
          "anonymous_moderators.current_account"
          username=this.currentUser.username
        }}
      </span>
  
      <span class="anonymous-moderator-switcher__status">
        {{#if this.currentUser.can_become_anonymous_moderator}}
          {{i18n "anonymous_moderators.signed_in_main"}}
        {{else}}
          {{i18n "anonymous_moderators.signed_in_anon"}}
        {{/if}}
      </span>
    </div>
  
    {{#if this.currentUser.can_become_anonymous_moderator}}
      <DButton
        @action={{this.becomeAnonModerator}}
        @label="anonymous_moderators.switch_to_anon"
        @icon="user-secret"
        @isLoading={{this.loading}}
      />
    {{else if this.currentUser.is_anonymous_moderator}}
      <DButton
        @action={{this.becomeMasterUser}}
        @label="anonymous_moderators.switch_to_master"
        @icon="user-secret"
        @isLoading={{this.loading}}
      />
    {{/if}}
  </div>
  */
  {
    "id": "b4fH6mMO",
    "block": "[[[10,0],[14,0,\"anonymous-moderator-switcher\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"anonymous-moderator-switcher__header\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"anonymous-moderator-switcher__user\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[\"anonymous_moderators.current_account\"],[[\"username\"],[[30,0,[\"currentUser\",\"username\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,1],[14,0,\"anonymous-moderator-switcher__status\"],[12],[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"can_become_anonymous_moderator\"]],[[[1,\"        \"],[1,[28,[35,0],[\"anonymous_moderators.signed_in_main\"],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,0],[\"anonymous_moderators.signed_in_anon\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"currentUser\",\"can_become_anonymous_moderator\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@action\",\"@label\",\"@icon\",\"@isLoading\"],[[30,0,[\"becomeAnonModerator\"]],\"anonymous_moderators.switch_to_anon\",\"user-secret\",[30,0,[\"loading\"]]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"currentUser\",\"is_anonymous_moderator\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@action\",\"@label\",\"@icon\",\"@isLoading\"],[[30,0,[\"becomeMasterUser\"]],\"anonymous_moderators.switch_to_master\",\"user-secret\",[30,0,[\"loading\"]]]],null],[1,\"\\n  \"]],[]],null]],[]]],[13]],[],false,[\"i18n\",\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-anonymous-moderators/discourse/components/anonymous-moderator-tab.hbs",
    "isStrictMode": false
  });
  class AnonymousModeratorTab extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    async becomeAnonModerator() {
      this.loading = true;
      await (0, _ajax.ajax)("/anonymous-moderators/become-anon", {
        method: "POST"
      });
      window.location.reload();
    }
    static #_3 = (() => dt7948.n(this.prototype, "becomeAnonModerator", [_object.action]))();
    async becomeMasterUser() {
      this.loading = true;
      await (0, _ajax.ajax)("/anonymous-moderators/become-master", {
        method: "POST"
      });
      window.location.reload();
    }
    static #_4 = (() => dt7948.n(this.prototype, "becomeMasterUser", [_object.action]))();
  }
  _exports.default = AnonymousModeratorTab;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AnonymousModeratorTab);
});